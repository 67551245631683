import * as React from "react";
import { useContext, createContext, useSyncExternalStore } from "react";
import { audioManagerSingleton } from "./audioManager";
import { defaultState } from "./state";
const CONTEXT = createContext(defaultState);
export const useAudioManagerContext = () => {
    const state = useContext(CONTEXT);
    return state;
};
export const AudioManagerContextProvider = ({ children, }) => {
    const state = useSyncExternalStore(audioManagerSingleton.subscribe, audioManagerSingleton.getSnapshot);
    return React.createElement(CONTEXT.Provider, { value: state }, children);
};
export default CONTEXT;

export var RepeatValues;
(function (RepeatValues) {
    RepeatValues[RepeatValues["Off"] = 0] = "Off";
    RepeatValues[RepeatValues["Single"] = 1] = "Single";
    RepeatValues[RepeatValues["All"] = 2] = "All";
})(RepeatValues || (RepeatValues = {}));
export var AudioStatus;
(function (AudioStatus) {
    AudioStatus[AudioStatus["Idle"] = 0] = "Idle";
    AudioStatus[AudioStatus["Playing"] = 1] = "Playing";
    AudioStatus[AudioStatus["Stopped"] = 2] = "Stopped";
    AudioStatus[AudioStatus["Paused"] = 3] = "Paused";
})(AudioStatus || (AudioStatus = {}));
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["currentTrackIndex"] = "currentTrackIndex";
    ActionTypes["audioStatus"] = "audioStatus";
    ActionTypes["SaveAndReload"] = "saveAndReload";
    ActionTypes["setStopped"] = "setStopped";
    ActionTypes["repeat"] = "repeat";
    ActionTypes["addTracks"] = "addTracks";
    ActionTypes["setNewTrackOrder"] = "setNewTrackOrder";
    ActionTypes["audioContextState"] = "audioContextState";
})(ActionTypes || (ActionTypes = {}));
// type MediaSessionProps = {
//   title: string;
//   artist: string;
//   album: string;
//   albumArtUrl: string;
// };

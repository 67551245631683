import * as React from "react";
import { Suspense } from "react";
import { AudioManagerContextProvider, useAudioManagerContext, } from "../audioManager";
import { CreateAudioContextButton } from "../components/CreateAudioContextButton";
// Don't load three.js until an audio context is reated
const FullApp = React.lazy(() => import("./FullApp"));
const App = () => {
    const { audioContextState } = useAudioManagerContext();
    return (React.createElement(React.Fragment, null,
        audioContextState !== "suspended" && (React.createElement(Suspense, { fallback: null },
            React.createElement(FullApp, null))),
        audioContextState === "suspended" && React.createElement(CreateAudioContextButton, null)));
};
const AppWithAudioContextProvider = () => (React.createElement(AudioManagerContextProvider, null,
    React.createElement(App, null)));
export default AppWithAudioContextProvider;
